<template>
  <Container :isLoading="isLoading">
    <template #content>
      <div class="flex items-center justify-between mb-8">
        <div class="flex items-center gap-4">
          <ExportButton
            @click="$emit('export', { ...$route.query })"
            v-if="settings.hasExport"
          />
          <div class="flex items-center gap-2">
            <!-- <Block
              @click="$emit('block')"
              v-if="settings.hasBlock && !selectedItems.isEmpty()"
            /> -->
            <DeleteButton
              @click="$emit('delete')"
              v-if="settings.hasDelete && !selectedItems.isEmpty()"
            />
            <NotifyAction
              @click="$emit('notify')"
              v-if="settings.hasNotify && !selectedItems.isEmpty()"
            />
            <button
              v-if="settings.isDraggable"
              @click="$emit('changeOrder')"
              class="btn button-secondary gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.5"
                height="20"
                viewBox="0 0 12.5 20"
              >
                <path
                  id="drag_indicator_FILL0_wght400_GRAD0_opsz48"
                  d="M282.185,276a2.192,2.192,0,1,1,1.547-.641A2.1,2.1,0,0,1,282.185,276Zm8.125,0a2.192,2.192,0,1,1,1.547-.641A2.1,2.1,0,0,1,290.31,276Zm-8.125-7.812a2.192,2.192,0,1,1,1.547-.641A2.1,2.1,0,0,1,282.185,268.188Zm8.125,0a2.192,2.192,0,1,1,1.547-.641A2.1,2.1,0,0,1,290.31,268.188Zm-8.125-7.812a2.192,2.192,0,1,1,1.547-.641A2.1,2.1,0,0,1,282.185,260.375Zm8.125,0a2.192,2.192,0,1,1,1.547-.641A2.1,2.1,0,0,1,290.31,260.375Z"
                  transform="translate(-280 -256)"
                  fill="#005caa"
                />
              </svg>
              <span>{{ $t("change-q-order") }}</span>
            </button>
          </div>
        </div>
        <div class="flex items-center gap-4">
          <template v-if="filterComp">
            <component :is="filterComp"></component>
          </template>
          <SearchInput v-if="settings.hasSearch" />
        </div>
      </div>

      <Table
        :headerCenter="settings.rowsOptions?.isCenter"
        :headers="tableHeader"
        :notSelectable="settings.notSelectable"
        @selectAll="$emit('toggleSelectAll')"
      >
        <template #body>
          <template v-if="dataList && !dataList.isEmpty()">
            <tr
              v-for="(item, index) in dataList"
              :key="index"
              class="group"
              :data-index="index"
              :draggable="settings.isDraggable"
              @dragstart="handleDragStart"
              @dragenter="handleDragEnter"
              @dragleave="handleDragLeave"
              @dragover="handleDragOver"
              @drop="handleDrop"
              :class="
                item && item?.id && selectedItems.includes(item.id)
                  ? '!bg-warning/25'
                  : ''
              "
            >
              <template v-if="item && item.id">
                <td
                  v-for="[key, value] in Object.entries(item)"
                  :key="key"
                  :class="
                    settings.rowsOptions?.isCenter
                      ? 'text-center'
                      : 'text-justify'
                  "
                >
                  <span v-if="key == 'id'" class="flex items-center">
                    <CheckBox
                      v-if="!settings.notSelectable"
                      :checked="selectedItems.includes(item.id)"
                      @change="$emit('toggleChecked', item.id)"
                    />
                    <span class="ms-2">
                      {{
                        (value as string).slice(0, 3) +
                        (value as string).slice(-3)
                      }}
                    </span>
                    {{ key }}
                  </span>
                  <slot
                    v-else
                    :name="`cell(${key})`"
                    :row="item"
                    :value="value"
                  >
                    <span>
                      {{ value }}
                    </span>
                  </slot>
                </td>
                <td class="overflow-hidden w-[180px]">
                  <div
                    class="flex items-center text-white transition-all duration-300 opacity-0 translate-x-28 group-hover:translate-x-0 group-active:opacity-100 group-hover:opacity-100"
                    :class="
                      selectedItems.includes(item.id)
                        ? '!opacity-100 !translate-x-0'
                        : ''
                    "
                  >
                    <button
                      v-if="settings.rowsOptions.hasView"
                      @click="$emit('view', item.id)"
                      class="flex items-center justify-center w-full h-full gap-2 p-3 text-sm bg-primary"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.467"
                        height="14.375"
                        viewBox="0 0 20.467 14.375"
                      >
                        <g
                          id="view-alt-1-svgrepo-com"
                          transform="translate(-2.256 -3.25)"
                        >
                          <g
                            id="Ellipse_457"
                            data-name="Ellipse 457"
                            transform="translate(8.799 8.745)"
                            fill="none"
                            stroke="#fff"
                            stroke-width="1.5"
                          >
                            <circle
                              cx="3.69"
                              cy="3.69"
                              r="3.69"
                              stroke="none"
                            />
                            <circle cx="3.69" cy="3.69" r="4.44" fill="none" />
                          </g>
                          <path
                            id="Path_17310"
                            data-name="Path 17310"
                            d="M21.978,12.435S20.924,4,12.489,4,3,12.435,3,12.435"
                            fill="none"
                            stroke="#fff"
                            stroke-width="1.5"
                          />
                        </g>
                      </svg>
                      <span>{{ $t("view") }}</span>
                    </button>
                    <button
                      v-if="settings.rowsOptions.hasEdit"
                      @click="() => $emit('edit', item.id)"
                      class="flex items-center justify-center w-full h-full gap-2 p-3 text-sm bg-warning text-dark800"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="14.029"
                        viewBox="0 0 24 24.029"
                      >
                        <path
                          id="edit_square_FILL0_wght400_GRAD0_opsz48"
                          d="M7.739,29.979A1.783,1.783,0,0,1,6,28.24V10.849A1.783,1.783,0,0,1,7.739,9.109H19.478l-1.739,1.739h-10V28.24H25.13V18.153l1.739-1.739V28.24a1.783,1.783,0,0,1-1.739,1.739ZM16.435,19.544Zm5.275-10.2,1.246,1.217L14.7,18.791v2.493h2.464l8.29-8.29,1.217,1.217-8.783,8.812H12.957V18.095Zm4.957,4.87L21.71,9.341l2.9-2.9a1.671,1.671,0,0,1,1.232-.493,1.624,1.624,0,0,1,1.232.522l2.435,2.464A1.735,1.735,0,0,1,30,10.167a1.592,1.592,0,0,1-.522,1.2Z"
                          transform="translate(-6 -5.95)"
                          fill="#2a2a2a"
                        />
                      </svg>

                      <span>{{ $t("edit") }}</span>
                    </button>
                    <template v-if="settings.rowsOptions.hasBlock && item.isActive != undefined">
                      <button
                        @click="$emit('block', item.id)"
                        v-if="item.isActive"
                        class="flex items-center justify-center w-full h-full p-3 text-sm text-warning bg-warning/50"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <path
                            id="Icon_metro-blocked"
                            data-name="Icon metro-blocked"
                            d="M19.642,4.857A10,10,0,0,0,5.5,19,10,10,0,0,0,19.642,4.857Zm.429,7.071a7.459,7.459,0,0,1-1.389,4.343L8.227,5.817a7.5,7.5,0,0,1,11.843,6.111Zm-15,0A7.459,7.459,0,0,1,6.46,7.585L16.914,18.039A7.5,7.5,0,0,1,5.071,11.928Z"
                            transform="translate(-2.571 -1.928)"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                      <button
                        @click="$emit('unBlock', item.id)"
                        v-else
                        class="flex items-center justify-center w-full h-full p-3 text-sm text-primary bg-tertiary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-unlock"
                        >
                          <rect
                            x="3"
                            y="11"
                            width="18"
                            height="11"
                            rx="2"
                            ry="2"
                          ></rect>
                          <path d="M7 11V7a5 5 0 0 1 9.9-1"></path>
                        </svg>
                      </button>
                    </template>
                    <button
                      @click="$emit('delete', item.id)"
                      v-if="settings.rowsOptions.hasDelete"
                      class="flex items-center justify-center w-full h-full p-3 text-sm bg-danger/25"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19.875"
                        height="19.876"
                        viewBox="0 0 19.875 19.876"
                      >
                        <path
                          id="delete-svgrepo-com"
                          d="M66.129,68.2H64.71a.71.71,0,0,1,0-1.42h5.679V64.646a.71.71,0,0,1,.71-.71h5.679a.71.71,0,0,1,.71.71v2.131h5.679a.71.71,0,0,1,0,1.42h-1.42V83.1a.71.71,0,0,1-.71.71h-14.2a.71.71,0,0,1-.71-.71Zm9.938-1.42v-1.42H71.808v1.42ZM67.549,82.393H80.326V68.2H67.549Zm4.259-2.839a.71.71,0,0,1-.71-.71v-7.1a.71.71,0,1,1,1.42,0v7.1A.71.71,0,0,1,71.808,79.553Zm4.259,0a.71.71,0,0,1-.71-.71v-7.1a.71.71,0,0,1,1.42,0v7.1A.71.71,0,0,1,76.067,79.553Z"
                          transform="translate(-64 -63.936)"
                          fill="#da2834"
                        />
                      </svg>
                    </button>
                  </div>
                </td>
              </template>
            </tr>
          </template>
        </template>
      </Table>

      <EmptyTable v-if="dataList.isEmpty()" />

      <Pagination
        v-if="pagination"
        :current_page="pagination.current_page"
        :last_page="pagination.last_page"
      />
    </template>
  </Container>
</template>
<script setup lang="ts">
import { watch, ref } from "vue";

import { type Component } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const deleteButtonDesc = ref("");

const props = defineProps<{
  dataList: any[];
  selectedItems: string[];
  tableHeader: LabelVal[];
  settings: AppContainerSettings;
  pagination?: Pagination;
  isLoading: boolean;
  filterComp?: Component;
}>();

const emit = defineEmits<{
  (event: "toggleChecked", args: string): void;
  (event: "toggleSelectAll", ...args: any[]): void;
  (event: "block", id: string): void;
  (event: "unBlock", id: string): void;
  (event: "delete", id: string): void;
  (event: "view", id: string): void;
  (event: "edit", id: string): void;
  (event: "listOrderChange", args: HandleListOrderChangeArgs): void;
  (event: "notify"): void;
  (event: "export", args?: any): void;
  (event: "changeOrder"): void;
}>();

watch(props.selectedItems, (val) => {
  if (val.length > 0) {
    deleteButtonDesc.value = t(
      "your-about-to-delete-val-length-account-are-you-sure",
      [val.length]
    );
  } else {
    deleteButtonDesc.value = "";
  }
});

function handleDragStart(e: any) {
  if (!e.dataTransfer || !e.currentTarget || !e.currentTarget?.dataset) return;
  e.dataTransfer.setData("text/plain", e.currentTarget.dataset.index);
}
function handleDragEnter(e: any) {
  if (!e.currentTarget) return;
  e.currentTarget.classList.add("hover");
}
function handleDragLeave(e: any) {
  if (!e.currentTarget) return;
  e.currentTarget.classList.remove("hover");
}
function handleDragOver(e: any) {
  e.preventDefault();
}
function handleDrop(e: any) {
  e.preventDefault();
  if (!e.currentTarget || !e.dataTransfer || !e.target || !e.target.parentNode)
    return;
  const itemIndex = e.dataTransfer.getData("text/plain"),
    droppedIndex = e.target.parentNode.dataset.index;
  Array.from(e.currentTarget?.parentNode.children || []).map((tr: any) => {
    tr.classList.remove("hover");
  });

  if (!itemIndex || !droppedIndex) return;

  emit("listOrderChange", { itemIndex, droppedIndex });
  // handleListChange(itemIndex, droppedIndex);
}

// function handleListChange(index: string, newIndex: string) {
//   let oldIndex = parseInt(index, 10),
//     newPositionIndex = parseInt(newIndex, 10),
//     movedItem = props.dataList[oldIndex];
//   if (!oldIndex || !newPositionIndex || !movedItem) return;
//   if (oldIndex == newPositionIndex) return;
//   emit("listOrderChange", { oldIndex, newPositionIndex, movedItem });
// }
</script>
