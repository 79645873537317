import { t } from "@/core/i18n";


const API_URL = "/v1.0/management/Users";


const NAME = "allUsers";

const settings = {
    hasView: true,
    hasExport: true,
    hasBlock: true,
    hasDelete: true,
    hasNotify: true,
    hasFilter: true,
    hasSearch: true,
    hasEdit: false,
    rowsOptions: {
        hasView: true,
        hasBlock: true,
        hasDelete: true,
        hasEdit: false,
        isCenter: false,
    } as RowsOptions
}

const tableHeader: LabelVal[] = [
    {
        label: 'id',
        value: 'id',
    },
    {
        label: t('name'),
        value: 'name',
    },
    {
        label: t('grade'),
        value: 'Grade',
    },
    {
        label: t('school'),
        value: 'School',
    },
    {
        label: t('level'),
        value: 'Level',
    },
    {
        label: t('account'),
        value: 'Account',
    },
    {
        label: t('type'),
        value: 'Type',
    },
    {
        label: t('created-on'),
        value: 'created_on',
    },
    {
        label: t('city'),
        value: 'City',
    },
    {
        label: 'isActive',
        value: 'isActive',
    },
    {
        label: t('action'),
        value: 'Action',
    }
]



export default {
    API_URL,
    settings,
    tableHeader,
    NAME
}
