import axios, { type AxiosInstance } from "axios";
import { useToast } from "vue-toastification";
import { firebaseAuth } from "../utils/firebase";
import config from "@/core/config";
import { getCurrentLocale, t } from "../i18n";

let httpClient: AxiosInstance;

function _createHttpClient(): void {
  const toast = useToast();
  httpClient = axios.create({
    baseURL: config.base_url,
    // withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": getCurrentLocale(),
    },
  });

  httpClient.interceptors.request.use((config) => {
    // const token = await firebaseAuth.currentUser?.getIdToken();
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  httpClient.interceptors.response.use(
    (response) => response,
    async (error: any) => {
      console.log("error", error);

      if (error.response?.status === 401) {
        await firebaseAuth.currentUser?.getIdToken(true); // refresh token
        axios.request(error.config).catch(async (responseError) => {
          if (responseError?.response?.status === 401) {
            await firebaseAuth.signOut();
            localStorage.removeItem("token");
            if (!location.href.includes("auth")) {
              location.href = "/auth/login";
            }
          }
        });
      }
      if (error.response?.status === 413) {
        toast.error(
          t("file-too-large-please-upload-file-less-than-config-maxfilesize", [
            config.maxFileSize,
          ])
        );
      }
      if (error.response?.data?.message) {
        toast.error(error.response?.data?.message);
      }

      // trigger 'loading=false' event here
      return Promise.reject(error);
    }
  );
}

export function useHttpClient() {
  if (!httpClient) {
    _createHttpClient();
  }

  return httpClient;
}
