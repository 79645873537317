<template>
  <div class="h-20 my-4 w-[400px] overflow-hidden">
    <swiper
      v-if="store.data && store.data.length > 0"
      :modules="[Navigation, Scrollbar, A11y]"
      :slides-per-view="4"
      :space-between="20"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
      class="h-full"
    >
      <SwiperSlide v-for="img in store.data.slice(0,store.data.length)" :key="img?.id" class="">
        <button type="button" @click="$emit('selectImage', img)">
          <Image :src="img?.link" class="rounded-2xl h-20 w-20 object-cover" />
        </button>
      </SwiperSlide>
    </swiper>
  </div>
</template>
<script lang="ts" setup>
import { onMounted } from "vue";
import { UploadType, useImages } from "../../../modules/Settings/store/images";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Scrollbar, A11y } from "swiper/modules";

defineEmits(["selectImage"]);
const props = defineProps<{
  type: UploadType;
}>();

const store = useImages();

onMounted(() => {
  store.fetchData(props.type);
});
</script>
<style>
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "" !important;
  font-family: none !important;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-after:after {
  content: "" !important;
  font-family: none !important;
}
</style>
