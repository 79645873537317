<template>
  <!-- outlet pages -->
  <RouterView/>
  <!--  -->
  <ConnectionStatus/>
  <!-- models -->
  <DeleteModal/>
  <SuccessModal/>
  <AlertModal/>
  <ConfirmationModel/>
  <ExportFileModal/>
  <!--  -->
</template>
<script setup lang="ts">
import ConnectionStatus from "@/core/components/layout/ConnectionStatus.vue";
import {onMounted, computed} from "vue";
import {setLocale} from "@/core/utils/functions";
import DeleteModal from "@/core/components/utils/modals/DeleteModal.vue";
import SuccessModal from "@/core/components/utils/modals/SuccessModal.vue";
import AlertModal from "@/core/components/utils/modals/AlertModal.vue";
import ExportFileModal from "@/core/components/utils/modals/ExportFileModal.vue";
import ConfirmationModel from "./core/components/utils/modals/ConfirmationModel.vue";


onMounted(() => {
  setLocale(localStorage.getItem("locale") || "en");
});

</script>
<style lang="scss">

</style>
